import thLocale from 'element-ui/lib/locale/lang/th' //引入element语言包
//中文对应的英文
const th = {
    text: {
        '品牌升级': '{name} การอัพเกรดแบรนด์',
        '了解详情': 'เรียนรู้เพิ่มเติม',
        '登录': 'เข้าสู่ระบบ',
        '注册': 'ลงทะเบียน',
        '让您的交易方式': 'ให้วิธีการซื้อขายของคุณ',
        '升级换代': 'อัพเกรดเปลี่ยนรุ่น',
        '选择全球最大的零售经纪商进行交易，畅享领先市场的条件。': 'เลือกใช้บริการจากตัวแทนซื้อขายที่ใหญ่ที่สุดในโลก เพื่อเข้าถึงเงื่อนไขที่เป็นผู้นำในตลาด',
        '尝试免费模拟账户': 'ลองใช้บัญชีจำลองฟรี',
        '70万+名活跃客户': 'ลูกค้าที่ใช้บริการกว่า 700,000 คน',
        '持有多个监管牌照': 'ได้รับใบอนุญาตจากหลายหน่วยงานควบคุม',
        '24/7 中文客服': 'บริการลูกค้าภาษาจีนตลอด 24 ชั่วโมง',
        'PCI DSS认证': 'ได้รับการรับรอง PCI DSS',
        '交易无需妥协': 'การซื้อขายโดยไม่ต้องทำข้อตกลง',
        '出金': 'การถอนเงิน',
        '即时出金': 'ถอนเงินทันที',
        '一键即刻批准入金和出金': 'อนุมัติการฝากและถอนทันทีด้วยปุ่มเดียว',
        '爆仓': 'การเจาะสิ่งปล่อย',
        '减少30%爆仓': 'ลดการเจาะสิ่งปล่อยไป 30%',
        '借力我们的专有爆仓保护功能': 'ใช้บริการการป้องกันการเจาะสิ่งปล่อยของเรา',
        '您将势不可挡': 'คุณจะได้รับการสนับสนุนที่ไม่หยุดยั้ง',
        '执行速度': 'ความเร็วในการดำเนินการ',
        '极速执行': 'ดำเนินการที่เร็วมาก',
        '无惧大订单，所有订单均': 'ไม่กังวลกับคำสั่งใหญ่ ทุกคำสั่ง',
        '将以毫秒级别速度执行': 'ถูกดำเนินการในระดับละเอียดมิลลิวินาที',
        '隔夜利息': 'ดอกเบี้ยค้างคืน',
        '0隔夜利息': 'ไม่มีดอกเบี้ยค้างคืน',
        '无惧隔夜持有杠杆头寸，任意调整持仓': 'ไม่กังวลกับการถือตำแหน่งมาร์จิน สามารถปรับตำแหน่งได้ตามต้องการ',
        '时长。条款和条件适用': 'ช่วงเวลา ขึ้นอยู่กับข้อกำหนดและเงื่อนไข',
        '进入全球市场，交易资产': 'เข้าสู่ตลาดโลก ซื้อขายทรัพย์สิน',
        '把握全球最热门资产带来的每个机会': 'จับคุ้มค่าทุกๆ โอกาสจากทรัพย์สินยอดนิยมทั่วโลก',
        '品种': 'ประเภท',
        '杠杆': 'ค่าเลเวอเรจ',
        '平均点差, 点': 'ค่าซื้อขายเฉลี่ย, จุด',
        '免隔夜利息': 'ไม่มีดอกเบี้ยค้างคืน',
        '黄金': 'ทองคำ',
        '美元': 'ดอลลาร์สหรัฐ',
        '原油': 'น้ำมันดิบ',
        '欧元': 'ยูโร',
        '美国华尔街30指数': 'ดัชนีวอลล์สตรีท 30 ของสหรัฐอเมริกา',
        '定制化': 'การปรับแต่ง',
        '支持': 'การสนับสนุน',
        '金属': 'โลหะ',
        '能源': 'พลังงาน',
        '货币': 'สกุลเงิน',
        '指数': 'ดัชนี',
        '股票': 'หุ้น',
        '适用隔夜利息': 'การปรับใช้ดอกเบี้ยค้างคืน',
        '把握每个机会': 'จับคุ้มค่าทุกๆ โอกาส',
        '随时、随地线上交易。网页端、手机端、电脑桌面端，供您任意选择': 'ซื้อขายออนไลน์ได้ตลอดเวลา ที่ได้ที่สุดสำหรับเว็บไซต์ มือถือ และเดสก์ท็อปคอมพิวเตอร์',
        'MetaTrader 5': 'MetaTrader 5',
        'FTOOO 网页版': '{name} เว็บ',
        'FTOOO交易应用': '{name} แอปพลิเคชันการซื้อขาย',
        '保护您的安全是我们的首要任务': 'การป้องกันความปลอดภัยของคุณคือสิ่งที่เราใส่ใจเป็นอันดับแรก',
        '从安全支付到负余额保护，我们全方位为您保驾护航': 'เราให้บริการความปลอดภัยที่ครอบคลุมตั้งแต่การชำระเงินถึงการป้องกันยอดคงเหลือติดลบ',
        '客户保护': 'การป้องกันลูกค้า',
        '为什么选择FTOOO': 'ทำไมต้องเลือก{name}',
        '自信交易': 'ซื้อขายอย่างมั่นใจ',
        '让数字说话。获取全球最大零售经纪商的支持，自信交易': 'ให้โลกของทุกคนเชื่อมั่นในการซื้อขาย',
        '万亿': 'ล้านล้าน',
        '19亿': '1.9 ล้าน',
        '走在市场前沿': 'ก้าวทันตลาด',
        '获取最新市场资讯、交易分析和功能更新': 'ได้รับข้อมูลทางตลาดล่าสุด การวิเคราะห์การซื้อขาย และการอัปเดตฟังก์ชัน',
        '查看所有市场新闻': 'ดูข่าวสารทั้งหมดในตลาด',
        '洞见': 'วิวัฒนาการ',
        '教育': 'การศึกษา',
        '哪些公司将在2020年代生存下来': 'บริษัทไหนจะอยู่รอดในทศวรรษ 2020',
        '图表形态：真的能反映未来的价格走向吗': 'รูปแบบแผนภูมิ: สามารถทำนายเส้นทางราคาในอนาคตได้จริงหรือไม่',
        '是什么在牵制比特币': 'สิ่งใดที่กำลังควบคุมบิตคอยน์',
        '即日起选择值得信赖的经纪商交易': 'ตั้งแต่วันนี้เลือกตัวแทนซื้อขายที่เชื่อถือได้',
        '您亲自了解为什么FTOOO成为了70多万名交易者': 'เข้าใจด้วยตนเองว่าทำไม{name}เป็นเลือกของมากกว่า 700,000 นายทุนและ 64,000 ห่วงโซ่พันธมิตร',
        '账户': 'บัญชีผู้ใช้',
        '标准型账户': 'บัญชีประเภทมาตรฐาน',
        '专业型账户': 'บัญชีประเภทวิชาชีพ',
        '模拟账户': 'บัญชีจำลอง',
        '社交交易账户': 'บัญชีการซื้อขายสังคม',
        '条件': 'เงื่อนไข',
        '入金和出金': 'ฝากเงินและถอนเงิน',
        '手续费': 'ค่าธรรมเนียม',
        '市场': 'ตลาด',
        '外汇差价合约(CFD)': 'สัญญาเชื่อมั่นในอัตราแลกเปลี่ยน (CFD)',
        '大宗商品差价合约(CFD)': 'สัญญาเชื่อมั่นในสินค้าเชิงมหภาค (CFD)',
        '股票差价合约(CFD)': 'สัญญาเชื่อมั่นในหุ้น (CFD)',
        '指数差价合约(CFD)': 'สัญญาเชื่อมั่นในดัชนี (CFD)',
        '加密货币差价合约(CFD)': 'สัญญาเชื่อมั่นในเงินดิจิทัล (CFD)',
        '平台': 'แพลตฟอร์ม',
        'MetaTrader 4': 'MetaTrader 4',
        'MetaTrader手机应用': 'แอปพลิเคชัน MetaTrader สำหรับมือถือ',
        'FTOOO交易终端': '{name} แพลตฟอร์มการซื้อขาย',
        'MetaTrader网页终端': 'เว็บแพลตฟอร์ม MetaTrader',
        '工具': 'เครื่องมือ',
        '分析工具': 'เครื่องมือวิเคราะห์',
        '财经日历': 'ปฏิทินเศรษฐกิจ',
        '投资计算器': 'เครื่องคิดเงินลงทุน',
        '货币转换器': 'เครื่องคำนวณแลกเปลี่ยนเงินตรา',
        '跳动点历史记录': 'ประวัติการกระโดดจุด',
        'VPS托管服务': 'บริการโฮสต์ VPS',
        'Trading Central 网络电视': 'Trading Central ทีวีอินเทอร์เน็ต',
        '关于 FTOOO': 'เกี่ยวกับ {name}',
        '联系我们': 'ติดต่อเรา',
        '帮助中心': 'ศูนย์ช่วยเหลือ',
        '底部1': 'ข้อมูลบริษัท',
        '底部2': 'ตลาด MOG ตั้งอยู่ที่วอลล์สตรีทในนิวยอร์กสหรัฐอเมริกา ชื่อเต็มคือ Morgan Exchange และเป็นบริษัทในเครือของ Morgan Stanley (MS) ซึ่งเป็นหนึ่งในบริษัทลูกที่ให้บริการทางการเงินสำคัญ ธุรกิจหลัก ได้แก่ การจัดการหลักทรัพย์ส่วนบุคคล การจัดการความมั่งคั่งส่วนตัว และการจัดการการลงทุน VIP.',
        '底部3': 'ความปลอดภัย',
        '底部4': 'มอร์แกน สแตนลีย์เป็นธนาคารลงทุนและบริษัทบริการทางการเงินข้ามชาติที่ตั้งอยู่ที่ 1585 บรอดเวย์ ในมิดทาวน์ แมนฮัตตัน นครนิวยอร์ก บริษัทมีสำนักงานอยู่ใน 41 ประเทศ มีพนักงานมากกว่า 75,000 คน ลูกค้าประกอบด้วยองค์กร รัฐบาล สถาบันและบุคคล มอร์แกน สแตนลีย์ติดอันดับที่ 61 ในรายการ 500 องค์กรใหญ่ที่สุดในอเมริกาของ Fortune ปี 2023 และอันดับที่ 30 ใน Forbes Global Top 100',
        '底部5': 'ประสบการณ์ผู้ใช้',
        '底部6': 'Mog Exchange เป็นบริษัทลูกของบริษัทจัดการการลงทุนของ Morgan Stanley (MS) และอยู่ภายใต้การกำกับดูแลของ SEC ของประเทศ นอกจากนี้ MOG ยังเป็นสถาบันการจัดการความมั่งคั่งระดับ VIP ที่ให้บริการทางการเงินอย่างครบวงจร ซึ่งก่อตั้งขึ้นในเดือนกันยายน ปี 2019 MOG ให้บริการธุรกรรมทางการเงิน 4 ประเภท ได้แก่ การซื้อขายหลักทรัพย์ การซื้อขายสัญญาฟิวเจอร์ส การซื้อขายหุ้นและการแลกเปลี่ยนเงินตราต่างประเทศ และการซื้อขายการจำนองของกองทุนเหมือง',
        '底部7': ' เว็บไซต์ MOG Exchange: https://mogexchange.net',
        '底部8': 'ที่อยู่บริษัท: 1585 บรอดเวย์, มิดทาวน์ แมนฮัตตัน, เมืองนิวยอร์ก',
        '底部9': 'อีเมล์บริษัท: customer.morgan@gmail.com',
        '底部10': 'โทรศัพท์/WhatsApp ติดต่อ: +1(646)3027648',
        '风险披露': 'การเปิดเผยความเสี่ยง',
        '防止洗钱': 'ป้องกันการฟอกเงิน',
        '隐私政策': 'นโยบายความเป็นส่วนตัว',
        '月交易量': 'ปริมาณการซื้อขายต่อเดือน',
        '2023年执行交易单数': 'จำนวนคำสั่งซื้อที่ดำเนินการปี 2023',
        '注册合作伙伴': 'พันธมิตรที่ลงทะเบียน',
        'Trustpilot评分': 'คะแนนจาก Trustpilot',
        'APP下载': 'ดาวน์โหลดแอป',
        '比特币': 'บิทคอยน์',
        '澳大利亚元': 'ออสเตรเลีย',
        '亚马逊指数': 'ดัชนี Amazon',
    },
    ...thLocale
}

export default th;