//导入两个文件
import en from './en';
import cn from './cn';
import tw from './tw';
import jp from './jp';
import kr from './kr';
import th from './th';
export default {
  en: en,
  cn: cn,
  tw: tw,
  jp: jp,
  kr: kr,
  th: th
}