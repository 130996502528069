import enLocale from 'element-ui/lib/locale/lang/en' //引入element语言包
//中文对应的英文
const en = {
  text: {
    '品牌升级': "We've taken our brand to the next level.",
    '了解详情': 'Learn more',
    '登录': 'Sign in',
    '注册': 'Register',
    '让您的交易方式': 'Upgrade the way',
    '升级换代': 'you trade',
    '选择全球最大的零售经纪商进行交易，畅享领先市场的条件。': "Trade with the world's largest retail broker and benefit from better-than-market conditions.",
    '尝试免费模拟账户': 'Try free demo',
    '70万+名活跃客户': '700,000+ active traders',
    '持有多个监管牌照': 'Multiple regulatory licenses',
    '24/7 中文客服': '24/7 customer support',
    'PCI DSS认证': 'PCI DSS certified',
    '交易无需妥协': 'Trade without tradeoffs',
    '出金': 'Withdrawals',
    '即时出金': 'Instant withdrawals',
    '一键即刻批准入金和出金': 'Get your deposits and withdrawals approved the moment you click the button.',
    '爆仓': 'Stop outs',
    '减少30%爆仓': '30% fewer stop outs',
    '借力我们的专有爆仓保护功能': 'Be unstoppable with our proprietary',
    '您将势不可挡': 'Stop Out Protection feature.',
    '执行速度': 'Execution speed',
    '极速执行': 'Ultra-fast execution',
    '无惧大订单，所有订单均': 'Execute your orders in milliseconds,',
    '将以毫秒级别速度执行': 'no matter how big they are.',
    '隔夜利息': 'Swaps',
    '0隔夜利息': 'No overnight fees',
    '无惧隔夜持有杠杆头寸，任意调整持仓': 'Hold your leveraged positions for as long as',
    '时长。条款和条件适用': 'you like, swap-free. T&C apply.',
    '进入全球市场，交易资产': 'Trade assets from global markets',
    '把握全球最热门资产带来的每个机会': 'Capitalize on every opportunity with the world’s most popular assets.',
    '品种': 'Instruments',
    '杠杆': 'Leverage',
    '平均点差, 点': 'Avg. spread, pips',
    '免隔夜利息': 'Swap-free',
    '黄金': 'Gold',
    '美元': 'US Dollar',
    '原油': 'Crude Oil',
    '欧元': 'Euro',
    '美国华尔街30指数': 'US Wall Street 30 Index',
    '定制化': 'Customizable',
    '支持': 'Available',
    '金属': 'Metals',
    '能源': 'Energies',
    '货币': 'Currencies',
    '指数': 'Indices',
    '股票': 'Stock',
    '适用隔夜利息': 'Swap applied',
    '把握每个机会': 'Seize every opportunity',
    '随时、随地线上交易。网页端、手机端、电脑桌面端，供您任意选择': 'Trade online anytime, anywhere. On web, mobile and desktop.',
    'MetaTrader 5': 'MetaTrader 5',
    'FTOOO 网页版': '{name} Terminal',
    'FTOOO交易应用': '{name} Trade app',
    '保护您的安全是我们的首要任务': 'Your security is our priority',
    '从安全支付到负余额保护，我们全方位为您保驾护航': 'From secure payments to negative balance protection, you are covered from every angle.',
    '客户保护': 'Client protection',
    '为什么选择FTOOO': 'Why {name}',
    '自信交易': 'Trade with confidence',
    '让数字说话。获取全球最大零售经纪商的支持，自信交易': 'The numbers speak for themselves. Trade with the support of the world’s largest retail broker.',
    '万亿':'trillion',
    '19亿': '19亿',
    '走在市场前沿': 'Stay ahead of the markets',
    '获取最新市场资讯、交易分析和功能更新': 'Get the latest market news, trading analysis, and feature updates.',
    '查看所有市场新闻': 'View all market news',
    '洞见': 'Insights',
    '教育': 'Market news',
    '哪些公司将在2020年代生存下来': "Which companies will survive in the 2020's",
    '图表形态：真的能反映未来的价格走向吗': 'Chart format: Can it really reflect future price trends',
    '是什么在牵制比特币': 'What is restraining Bitcoin',
    '即日起选择值得信赖的经纪商交易': 'Trade with a trusted broker today',
    '您亲自了解为什么FTOOO成为了70多万名交易者': 'See for yourself why {name} is the broker of choice for over 700,000 traders and 64,000 partners.',
    '账户': 'Accounts',
    '标准型账户': 'Standard accounts',
    '专业型账户': 'Professional accounts',
    '模拟账户': 'Demo account',
    '社交交易账户': 'Social Trading accounts',
    '条件': 'Conditions',
    '入金和出金': 'Deposits and withdrawals',
    '手续费': 'Fees',
    '市场': 'Client protection',
    '外汇差价合约(CFD)': 'Forex CFD',
    '大宗商品差价合约(CFD)': 'Commodities CFD)',
    '股票差价合约(CFD)': 'Stocks CFD',
    '指数差价合约(CFD)': 'Indices CFD)',
    '加密货币差价合约(CFD)': 'Crypto CFD',
    '平台': 'Platforms',
    'MetaTrader 4': 'MetaTrader 4',
    'MetaTrader手机应用': 'MetaTrader mobile',
    'FTOOO交易终端': '{name} Terminal',
    'MetaTrader网页终端': 'MetaTrader WebTerminal',
    '工具': 'Tools',
    '分析工具': 'Analytical tools',
    '财经日历': 'Economic calendar',
    '投资计算器': 'Investment calculator',
    '货币转换器': 'Currency converter',
    '跳动点历史记录': 'Tick history',
    'VPS托管服务': 'VPS hosting',
    'Trading Central 网络电视': 'Trading Central WebTV',
    '关于 FTOOO': 'About {name}',
    '联系我们': 'Contact us',
    '帮助中心': 'Help Center',
    '底部1': 'Company Background',
    '底部2': 'MOG Exchange, headquartered on Wall Street in New York, USA, is officially known as Morgan Exchange and is a subsidiary of Morgan Stanley\'s (MS) investment management arm, serving as one of its key financial service subsidiaries. Its main business areas include individual securities management, private wealth management, and VIP investment management.',
    '底部3': 'Company Strength:',
    '底部4':'Morgan Stanley is a multinational investment bank and financial services company headquartered at 1585 Broadway in Midtown Manhattan, New York City. The company has offices in 41 countries and employs over 75,000 staff, serving clients including corporations, governments, institutions, and individuals. Morgan Stanley ranked 61st in total revenue in Fortune\'s 2023 list of America\'s 500 largest corporations, and 30th in Forbes\' Global Top 100.',
    '底部5': 'User Experience:',
    "底部6":'As a subsidiary of Morgan Stanley\'s (MS) investment management, Mog Exchange is regulated by the national SEC. Additionally, MOG is a comprehensive VIP wealth management institution that offers full financial services, established in September 2019. MOG provides four major types of financial trading services including securities trading, futures contracts trading, stock and forex trading, and mining pool stake trading.',
    '底部7': 'MOG Exchange website: https://mogexchange.net\n',
    '底部8': 'Company address: 1585 Broadway, Midtown Manhattan, New York City',
    '底部9': 'Corporate email: customer.morgan@gmail.com',
    '底部10': 'Contact phone/WhatsApp: +1(646)3027648',
    '风险披露': 'Risk Disclosure',
    '防止洗钱': 'Preventing Money Laundering',
    '隐私政策': 'Privacy Policy',
    '月交易量': 'monthly trading volume',
    '2023年执行交易单数': 'trades executed in 2023',
    '注册合作伙伴': 'registered partners',
    'Trustpilot评分': 'Trustpilot rating',
    'APP下载' : 'App',
    '比特币': 'Bitcoin',
    '澳大利亚元': 'Australian dollar',
    '亚马逊指数': 'Amazon index',
  },
  ...enLocale
}
 
export default en;