import jpLocale from 'element-ui/lib/locale/lang/ja' //引入element语言包
//中文对应的英文
const jp = {
    text: {
        '品牌升级': '{name}ブランドアップグレード。',
        '了解详情': '詳細を確認',
        '登录': 'ログイン',
        '注册': '登録',
        '让您的交易方式': 'あなたの取引方法を',
        '升级换代': 'アップグレード',
        '选择全球最大的零售经纪商进行交易，畅享领先市场的条件。': '世界最大の小売ブローカーを選んで、市場をリードする条件で取引してください。',
        '尝试免费模拟账户': '無料のデモ口座をお試しください',
        '70万+名活跃客户': '70万以上のアクティブな顧客',
        '持有多个监管牌照': '複数の規制ライセンスを保有',
        '24/7 中文客服': '24時間365日中国語カスタマーサポート',
        'PCI DSS认证': 'PCI DSS認証',
        '交易无需妥协': '取引に妥協はいりません',
        '出金': '出金',
        '即时出金': '即時出金',
        '一键即刻批准入金和出金': 'ワンクリックで入金および出金を即座に承認',
        '爆仓': 'ロスカット',
        '减少30%爆仓': 'ロスカットを30%減少',
        '借力我们的专有爆仓保护功能': '独自のロスカット保護機能を活用',
        '您将势不可挡': 'あなたは止まらないでしょう',
        '执行速度': '実行速度',
        '极速执行': '超高速実行',
        '无惧大订单，所有订单均': '大口注文でも全ての注文は',
        '将以毫秒级别速度执行': 'ミリ秒単位で実行されます',
        '隔夜利息': 'スワップポイント',
        '0隔夜利息': '0スワップポイント',
        '无惧隔夜持有杠杆头寸，任意调整持仓': 'オーバーナイトでのレバレッジポジションを持っても、ポジションを自由に調整',
        '时长。条款和条件适用': '適用される規定と条件による',
        '进入全球市场，交易资产': 'グローバル市場にアクセスして、資産を取引',
        '把握全球最热门资产带来的每个机会': 'グローバルで最も人気のある資産の機会を逃さないでください',
        '品种': '種類',
        '杠杆': 'レバレッジ',
        '平均点差, 点': '平均スプレッド、ポイント',
        '免隔夜利息': 'スワップポイント無料',
        '黄金': 'ゴールド',
        '美元': '米ドル',
        '原油': '原油',
        '欧元': 'ユーロ',
        '美国华尔街30指数': '米国ウォール街30指数',
        '定制化': 'カスタマイズ',
        '支持': 'サポート',
        '金属': '金属',
        '能源': 'エネルギー',
        '货币': '通貨',
        '指数': '指数',
        '股票': '株式',
        '适用隔夜利息': 'スワップポイントの適用',
        '把握每个机会': 'すべての機会をつかむ',
        '随时、随地线上交易。网页端、手机端、电脑桌面端，供您任意选择': 'いつでもどこでもオンライン取引。ウェブ、モバイル、デスクトップからお好きなものを選択',
        'MetaTrader 5': 'MetaTrader 5',
        'FTOOO 网页版': '{name}ウェブ版',
        'FTOOO交易应用': '{name}取引アプリ',
        '保护您的安全是我们的首要任务': 'あなたの安全を守ることが私たちの第一の任務です',
        '从安全支付到负余额保护，我们全方位为您保驾护航': '安全な支払いからネガティブバランス保護まで、あらゆる面でサポートします',
        '客户保护': '顧客保護',
        '为什么选择FTOOO': '{name}を選ぶ理由',
        '自信交易': '自信を持って取引',
        '让数字说话。获取全球最大零售经纪商的支持，自信交易': '世界最大の小売ブローカーのサポートを得て、自信を持って取引',
        '万亿': '兆',
        '19亿': '19億',
        '走在市场前沿': '市場の最前線を行く',
        '获取最新市场资讯、交易分析和功能更新': '最新の市場情報、取引分析、機能更新を入手',
        '查看所有市场新闻': 'すべての市場ニュースをチェック',
        '洞见': '洞察',
        '教育': '教育',
        '哪些公司将在2020年代生存下来': 'どの企業が2020年代を生き残るのか',
        '图表形态：真的能反映未来的价格走向吗': 'チャートパターン：本当に将来の価格動向を反映することができるのか',
        '是什么在牵制比特币': 'ビットコインを牽制しているのは何か',
        '即日起选择值得信赖的经纪商交易': '信頼できるブローカーを選んで今すぐ取引',
        '您亲自了解为什么FTOOO成为了70多万名交易者': '{name}が70万人以上のトレーダーと6.4万人以上のパートナーの選択肢になった理由をご自身でご理解ください',
        '账户': 'アカウント',
        '标准型账户': 'スタンダードアカウント',
        '专业型账户': 'プロフェッショナルアカウント',
        '模拟账户': 'デモ口座',
        '社交交易账户': 'ソーシャルトレーディングアカウント',
        '条件': '条件',
        '入金和出金': '入金と出金',
        '手续费': '手数料',
        '市场': '市場',
        '外汇差价合约(CFD)': '外国為替差金契約（CFD）',
        '大宗商品差价合约(CFD)': '商品差金契約（CFD）',
        '股票差价合约(CFD)': '株式差金契約（CFD）',
        '指数差价合约(CFD)': '指数差金契約（CFD）',
        '加密货币差价合约(CFD)': '暗号通貨差金契約（CFD）',
        '平台': 'プラットフォーム',
        'MetaTrader 4': 'MetaTrader 4',
        'MetaTrader手机应用': 'MetaTraderモバイルアプリ',
        'FTOOO交易终端': '{name}取引端末',
        'MetaTrader网页终端': 'MetaTraderウェブ端末',
        '工具': 'ツール',
        '分析工具': '分析ツール',
        '财经日历': '経済カレンダー',
        '投资计算器': '投資計算機',
        '货币转换器': '通貨コンバーター',
        '跳动点历史记录': 'スプレッド履歴記録',
        'VPS托管服务': 'VPSホスティングサービス',
        'Trading Central 网络电视': 'Trading Centralネットワークテレビ',
        '关于 FTOOO': '{name}について',
        '联系我们': 'お問い合わせ',
        '帮助中心': 'ヘルプセンター',
        '媒体上的FTOOO形象': 'メディアでの{name}のイメージ',
        '社会上的FTOOO形象': '社会での{name}のイメージ',
        'FTOOO精英联盟': '{name}エリート連盟',
        '博客': 'ブログ',
        '公司信息': '会社情報',
        '监管': '規制',
        '法律文件': '法的文書',
        '财务报告': '財務報告',
        '赔偿基金': '補償基金',
        '产品组合': '製品ポートフォリオ',
        '尊享会员': 'VIP会員',
        '社交交易': 'ソーシャルトレーディング',
        '底部1': '会社背景',
        '底部2': 'MOG取引所は、アメリカ合衆国ニューヨークのウォールストリートに本社を置いており、正式名称はモルガン取引所で、モルガン・スタンレー（MS）の投資管理機関に属している主要な金融サービス子会社の一つです。主な業務範囲は個人証券管理、プライベートウェルスマネジメント、およびVIP投資管理です。',
        '底部3': '会社の強み',
        '底部4': 'モルガン・スタンレーは、ニューヨーク市ミッドタウン・マンハッタンのブロードウェイ1585番地に本社を置く、アメリカの多国籍投資銀行および金融サービス会社です。同社は41カ国にオフィスを構え、75,000人以上の従業員を雇用しており、顧客には企業、政府、機関、個人が含まれます。モルガン・スタンレーは2023年のフォーチュン誌のアメリカ企業500強で総収入で61位、フォーブスのグローバルトップ100で30位にランクインしています。',
        '底部5': 'ユーザーエクスペリエンス',
        '底部6': 'Mog Exchangeは、モルガン・スタンレー（MS）の投資管理部門の子会社として、国家SECの規制を受けています。また、MOGは2019年9月に設立された包括的なVIP財産管理機関で、全面的な金融サービスを提供しています。MOGは、証券取引、先物契約取引、株式及び外国為替取引、マイニングプールのステーク取引の4つの主要な金融取引サービスを提供しています。',
        '底部7': 'MOG取引所のウェブサイト: https://mogexchange.net',
        '底部8': '会社の住所: アメリカ、ニューヨーク市マンハッタン中城、ブロードウェイ1585番地',
        '底部9': '企業メール: customer.morgan@gmail.com',
        '底部10': '連絡先電話/WhatsApp: +1(646)3027648',
        '风险披露': 'リスク開示',
        '防止洗钱': 'マネーロンダリング防止',
        '隐私政策': 'プライバシーポリシー',
        '月交易量': '月間取引量',
        '2023年执行交易单数': '2023年実行取引件数',
        '注册合作伙伴': '登録パートナー',
        'Trustpilot评分': 'Trustpilotスコア',
        'APP下载': 'アプリ',
        '比特币': 'ビットコインです',
        '澳大利亚元': 'オーストラリアドルです',
        '亚马逊指数': 'アマゾン指数です',
    }
    ,
    ...jpLocale
}

export default jp;