import zhLocale from 'element-ui/lib/locale/lang/zh-CN' //引入element语言包
//需要转换的中文
const cn = {
    text: {
        '品牌升级': '{name}品牌升级。',
        '了解详情': '了解详情',
        '登录': '登录',
        '注册': '注册',
        '让您的交易方式': '让您的交易方式',
        '升级换代': '升级换代',
        '选择全球最大的零售经纪商进行交易，畅享领先市场的条件。': '选择全球最大的零售经纪商进行交易，畅享领先市场的条件。',
        '尝试免费模拟账户': '尝试免费模拟账户',
        '70万+名活跃客户': '70万+名活跃客户',
        '持有多个监管牌照': '持有多个监管牌照',
        '24/7 中文客服': '24/7 中文客服',
        'PCI DSS认证': 'PCI DSS认证',
        '交易无需妥协': '交易无需妥协',
        '出金': '出金',
        '即时出金': '即时出金',
        '一键即刻批准入金和出金': '一键即刻批准入金和出金',
        '爆仓': '爆仓',
        '减少30%爆仓': '减少30%爆仓',
        '借力我们的专有爆仓保护功能': '借力我们的专有爆仓保护功能',
        '您将势不可挡': '您将势不可挡',
        '执行速度': '执行速度',
        '极速执行': '极速执行',
        '无惧大订单，所有订单均': '无惧大订单，所有订单均',
        '将以毫秒级别速度执行': '将以毫秒级别速度执行',
        '隔夜利息': '隔夜利息',
        '0隔夜利息': '0隔夜利息',
        '无惧隔夜持有杠杆头寸，任意调整持仓': '无惧隔夜持有杠杆头寸，任意调整持仓',
        '时长。条款和条件适用': '时长。条款和条件适用',
        '进入全球市场，交易资产': '进入全球市场，交易资产',
        '把握全球最热门资产带来的每个机会': '把握全球最热门资产带来的每个机会',
        '品种': '品种',
        '杠杆': '杠杆',
        '平均点差, 点': '平均点差, 点',
        '免隔夜利息': '免隔夜利息',
        '黄金': '黄金',
        '美元': '美元',
        '原油': '原油',
        '欧元': '欧元',
        '美国华尔街30指数': '美国华尔街30指数',
        '定制化': '定制化',
        '支持': '支持',
        '金属': '金属',
        '能源': '能源',
        '货币': '货币',
        '指数': '指数',
        '股票': '股票',
        '适用隔夜利息': '适用隔夜利息',
        '把握每个机会': '把握每个机会',
        '随时、随地线上交易。网页端、手机端、电脑桌面端，供您任意选择': '随时、随地线上交易。网页端、手机端、电脑桌面端，供您任意选择',
        'MetaTrader 5': 'MetaTrader 5',
        'FTOOO 网页版': '{name} 网页版',
        'FTOOO交易应用': '{name} 交易应用',
        '保护您的安全是我们的首要任务': '保护您的安全是我们的首要任务',
        '从安全支付到负余额保护，我们全方位为您保驾护航': '从安全支付到负余额保护，我们全方位为您保驾护航',
        '客户保护': '客户保护',
        '为什么选择FTOOO': '为什么选择{name}',
        '自信交易': '自信交易',
        '让数字说话。获取全球最大零售经纪商的支持，自信交易': '让数字说话。获取全球最大零售经纪商的支持，自信交易',
        '万亿':'万亿',
        '19亿': '19亿',
        '走在市场前沿': '走在市场前沿',
        '获取最新市场资讯、交易分析和功能更新': '获取最新市场资讯、交易分析和功能更新',
        '查看所有市场新闻': '查看所有市场新闻',
        '洞见': '洞见',
        '教育': '教育',
        '哪些公司将在2020年代生存下来': '哪些公司将在2020年代生存下来',
        '图表形态：真的能反映未来的价格走向吗': '图表形态：真的能反映未来的价格走向吗',
        '是什么在牵制比特币': '是什么在牵制比特币',
        '即日起选择值得信赖的经纪商交易': '即日起选择值得信赖的经纪商交易',
        '您亲自了解为什么FTOOO成为了70多万名交易者': '您亲自了解为什么{name}成为了70多万名交易者和6.4万多名合作伙伴的选择',
        '账户': '账户',
        '标准型账户': '标准型账户',
        '专业型账户': '专业型账户',
        '模拟账户': '模拟账户',
        '社交交易账户': '社交交易账户',
        '条件': '条件',
        '入金和出金': '入金和出金',
        '手续费': '手续费',
        '市场': '市场',
        '外汇差价合约(CFD)': '外汇差价合约(CFD)',
        '大宗商品差价合约(CFD)': '大宗商品差价合约(CFD)',
        '股票差价合约(CFD)': '股票差价合约(CFD)',
        '指数差价合约(CFD)': '指数差价合约(CFD)',
        '加密货币差价合约(CFD)': '加密货币差价合约(CFD)',
        '平台': '平台',
        'MetaTrader 4': 'MetaTrader 4',
        'MetaTrader手机应用': 'MetaTrader手机应用',
        'FTOOO交易终端': '{name}交易终端',
        'MetaTrader网页终端': 'MetaTrader网页终端',
        '工具': '工具',
        '分析工具': '分析工具',
        '财经日历': '财经日历',
        '投资计算器': '投资计算器',
        '货币转换器': '货币转换器',
        '跳动点历史记录': '跳动点历史记录',
        'VPS托管服务': 'VPS托管服务',
        'Trading Central 网络电视': 'Trading Central 网络电视',
        '关于 FTOOO': '关于 {name}',
        '联系我们': '联系我们',
        '帮助中心': '帮助中心',
        '底部1': '在数字货币的兴起与发展中，交易平台扮演着至关重要的角色。Mog Exchange，隶属于Timic.ltd，作为一家新兴的虚拟货币交易平台，已经成功获得CBCS（中央银行加密货币服务）的监管牌照，标志着我们在安全性、合规性以及服务质量上的高标准和严要求。本文旨在详细介绍Mog Exchange平台的特色、服务以及我们对未来数字货币交易领域的展望。',
        '底部2': '公司背景',
        '底部3': 'Timic.ltd成立于数字货币初露锋芒的时代，目睹了加密货币从边缘市场成长为全球金融市场不可或缺的一部分的全过程。Mog Exchange作为其旗下的交易平台，继承了Timic.ltd对技术创新和客户服务的深厚理解，致力于为全球用户提供安全、便捷、高效的数字货币交易服务。',
        '底部4': '安全性',
        '底部5': '在数字货币交易领域，安全性始终是用户最关心的问题之一。Mog Exchange深知这一点，因此投入大量资源确保平台的安全性。我们采用多层次安全策略，包括但不限于冷热钱包分离、多重签名、实时监控系统等先进技术，以确保用户资产的安全。此外，获得CBCS监管牌照进一步证明了我们在安全性和合规性方面的承诺和实力。',
        '底部6': '用户体验',
        '底部7': 'Mog Exchange致力于为用户提供流畅的交易体验。我们的用户界面简洁易用，不论是资深交易者还是数字货币新手都能快速上手。同时，我们的客户服务团队7*24小时待命，随时解答用户的疑问，解决可能遇到的问题，确保用户交易过程中无忧。',
        '底部8': '产品与服务',
        '底部9': 'Mog Exchange提供广泛的产品和服务，包括但不限于：',
        '底部10': '- *币币交易*：支持多种主流与小众数字货币之间的交易，满足不同用户的需求。',
        '底部11': '- *合约交易*：为高级用户提供多种合约交易选项，包括永续合约等，满足用户对高风险、高回报交易的追求。',
        '底部12': '- *法币交易*：支持多种法定货币兑换数字货币，让数字货币的买入和卖出更加便捷。',
        '底部13': '- *期权交易*：提供全面的期权交易服务，使投资者能够通过灵活的策略在波动的市场中管理风险，寻求利润。',
        '底部14': '技术创新',
        '底部15': '在迅速变化的数字货币市场，技术创新是保持竞争力的关键。Mog Exchange拥有一支由业内顶尖技术人才组成的团队，不断探索和实践区块链技术的最新应用。我们的研发团队致力于提升交易效率，降低交易成本，同时探索区块链技术在金融、供应链等领域的应用，以拓展我们的服务范围。',
        '底部16': '未来展望',
        '底部17': 'Mog Exchange不仅仅是一个数字货币交易平台，我们的愿景是成为连接用户与数字货币世界的桥梁。我们相信，随着区块链技术的不断成熟和数字货币应用场景的拓展，数字货币将在全球经济中扮演越来越重要的角色。Mog Exchange将持续关注市场动态，不断优化产品和服务，为用户提供更多元化、高质量的服务。',
        '底部18': '总之，Mog Exchange以其高标准的安全性、优质的用户体验、丰富的产品与服务以及不断的技术创新，致力于为全球用户提供顶级的数字货币交易体验。随着我们不断进步和成长，我们期待与全球的用户一起探索数字货币的无限可能。在这个充满挑战与机遇的时代，Mog Exchange将与您携手前行，共创美好的数字未来。',
        '风险披露': '风险披露',
        '防止洗钱': '防止洗钱',
        '隐私政策': '隐私政策',
        '月交易量': '月交易量', 
        '2023年执行交易单数': '2023年执行交易单数', 
        '注册合作伙伴': '注册合作伙伴', 
        'Trustpilot评分': 'Trustpilot评分',
        'APP下载' : 'APP',
        '比特币': '比特币',
        '澳大利亚元': '澳大利亚元',
        '亚马逊指数': '亚马逊指数',
    },
    ...zhLocale
}

export default cn;