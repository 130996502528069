<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {

};
</script>

<style>
body {
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}
</style>
