import twLocale from 'element-ui/lib/locale/lang/zh-TW' //引入element语言包
//中文对应的英文
const tw = {
    text: {
        '品牌升级': '{name}品牌升級。',
        '了解详情': '了解更多',
        '登录': '登錄',
        '注册': '註冊',
        '让您的交易方式': '升級您的交易方式',
        '升级换代': '升級換代',
        '选择全球最大的零售经纪商进行交易，畅享领先市场的条件。': '與全球最大的零售經紀商交易，享受超越市場的條件。',
        '尝试免费模拟账户': '試用免費模擬帳戶',
        '70万+名活跃客户': '超過700,000名活躍交易者',
        '持有多个监管牌照': '持有多個監管牌照',
        '24/7 中文客服': '24/7 中文客服支持',
        'PCI DSS认证': 'PCI DSS 認證',
        '交易无需妥协': '無需妥協地進行交易',
        '出金': '出金',
        '即时出金': '即時出金',
        '一键即刻批准入金和出金': '點擊按鈕即時批准您的存款和提款。',
        '爆仓': '爆倉',
        '减少30%爆仓': '減少30%的爆倉率',
        '借力我们的专有爆仓保护功能': '使用我們的專有爆倉保護功能，助您勇往直前。',
        '您将势不可挡': '您將勢不可擋',
        '执行速度': '執行速度',
        '极速执行': '極速執行',
        '无惧大订单，所有订单均': '無論大小訂單，',
        '将以毫秒级别速度执行': '均以毫秒級速度執行。',
        '隔夜利息': '隔夜利息',
        '0隔夜利息': '零隔夜利息',
        '无惧隔夜持有杠杆头寸，任意调整持仓': '持有槓桿頭寸無需擔心隔夜費用，隨心調整持倉。',
        '时长。条款和条件适用': '條款和條件適用',
        '进入全球市场，交易资产': '在全球市場交易資產',
        '把握全球最热门资产带来的每个机会': '把握全球最熱門資產帶來的每個機會。',
        '品种': '產品類別',
        '杠杆': '槓桿',
        '平均点差, 点': '平均點差，點',
        '免隔夜利息': '免隔夜利息',
        '黄金': '黃金',
        '美元': '美元',
        '原油': '原油',
        '欧元': '歐元',
        '美国华尔街30指数': '美國華爾街30指數',
        '定制化': '客製化',
        '支持': '支援',
        '金属': '金屬',
        '能源': '能源',
        '货币': '貨幣',
        '指数': '指數',
        '股票': '股票',
        '适用隔夜利息': '適用隔夜利息',
        '把握每个机会': '把握每個機會',
        '随时、随地线上交易。网页端、手机端、电脑桌面端，供您任意选择': '隨時、隨地線上交易。網頁、手機和桌面端，供您選擇。',
        'MetaTrader 5': 'MetaTrader 5',
        'FTOOO 网页版': '{name} Terminal',
        'FTOOO交易应用': '{name} 交易應用',
        '保护您的安全是我们的首要任务': '您的安全是我們的首要任務',
        '从安全支付到负余额保护，我们全方位为您保驾护航': '從安全支付到負餘額保護，我們全方位為您保障。',
        '客户保护': '客戶保護',
        '为什么选择FTOOO': '為什麼選擇{name}',
        '自信交易': '自信交易',
        '让数字说话。获取全球最大零售经纪商的支持，自信交易': '數據不偽。在全球最大零售經紀商的支持下，自信交易。',
        '万亿': '萬億',
        '19亿': '19億',
        '走在市场前沿': '走在市場前沿',
        '获取最新市场资讯、交易分析和功能更新': '獲取最新市場資訊、交易分析和功能更新。',
        '查看所有市场新闻': '查看所有市場新聞',
        '洞见': '洞見',
        '教育': '市場新聞',
        '哪些公司将在2020年代生存下来': '哪些公司將在2020年代生存下來',
        '图表形态：真的能反映未来的价格走向吗': '圖表形態：真的能反映未來的價格走向嗎',
        '是什么在牵制比特币': '是什麼在牽制比特幣',
        '即日起选择值得信赖的经纪商交易': '即日起選擇值得信賴的經紀商交易',
        '您亲自了解为什么FTOOO成为了70多万名交易者': '親自了解為什麼{name}成為了超過700,000名交易者和64,000名合作夥伴的首選。',
        '账户': '帳戶',
        '标准型账户': '標準型帳戶',
        '专业型账户': '專業型帳戶',
        '模拟账户': '模擬帳戶',
        '社交交易账户': '社交交易帳戶',
        '条件': '條件',
        '入金和出金': '存款和提款',
        '手续费': '手續費',
        '市场': '客戶保護',
        '外汇差价合约(CFD)': '外匯差價合約(CFD)',
        '大宗商品差价合约(CFD)': '大宗商品差價合約(CFD)',
        '股票差价合约(CFD)': '股票差價合約(CFD)',
        '指数差价合约(CFD)': '指數差價合約(CFD)',
        '加密货币差价合约(CFD)': '加密貨幣差價合約(CFD)',
        '平台': '平台',
        'MetaTrader 4': 'MetaTrader 4',
        'MetaTrader手机应用': 'MetaTrader 手機應用',
        'FTOOO交易终端': '{name} 交易終端',
        'MetaTrader网页终端': 'MetaTrader 網頁終端',
        '工具': '工具',
        '分析工具': '分析工具',
        '财经日历': '財經日曆',
        '投资计算器': '投資計算器',
        '货币转换器': '貨幣轉換器',
        '跳动点历史记录': '跳動點歷史記錄',
        'VPS托管服务': 'VPS 托管服務',
        'Trading Central 网络电视': 'Trading Central 網絡電視',
        '关于 FTOOO': '關於{name}',
        '联系我们': '聯繫我們',
        '帮助中心': '幫助中心',
        '底部1': '公司背景',
        '底部2': 'MOG交易所，總部設於美國紐約華爾街，全名為摩根交易所，隸屬於摩根士丹利（MS）投資管理機構，是其重要的金融服務子公司之一。主要業務範圍包括個人證券管理、私人財富管理以及VIP投資管理。',
        '底部3': '公司實力',
        '底部4': '摩根士丹利是一家位於紐約曼哈頓中城百老匯1585號的美國跨國投資銀行和金融服務公司。該公司在41個國家設有辦公室，擁有超過75,000名員工，客戶包括企業、政府、機構及個人。摩根士丹利在2023年《財富》美國企業500強的總收入排名中位列第61位，在《福布斯》全球100強中排名第30位',
        '底部5': '用戶體驗',
        '底部6': 'Mog Exchange作為摩根士丹利（MS）投資管理機構的子公司之一，接受國家SEC監管。同時，MOG是一家提供全面金融服務的綜合型VIP財富管理機構，成立於2019年9月。MOG提供四大類金融交易服務，包括證券交易、期貨合約交易、股票及外匯交易、礦池質押交易',
        '底部7': 'MOG交易網址：https://mogexchange.net',
        '底部8': '公司地址：紐約市曼哈頓中城百老匯1585號',
        '底部9': '企業信箱：customer.morgan@gmail.com：',
        '底部10': '聯絡電話/WhatsApp：+1(646)3027648',
        '风险披露': '風險披露',
        '防止洗钱': '防止洗錢',
        '隐私政策': '隱私政策',
        '月交易量': '月交易量',
        '2023年执行交易单数': '2023年執行交易單數',
        '注册合作伙伴': '註冊合作夥伴',
        'Trustpilot评分': 'Trustpilot 評分',
        'APP下载': 'App',
        '比特币': '比特幣',
        '澳大利亚元': '澳大利亞元',
        '亚马逊指数': '亞馬遜指數',
    },
    ...twLocale
}

export default tw;