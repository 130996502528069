import krLocale from 'element-ui/lib/locale/lang/ko' //引入element语言包
//中文对应的英文
const kr = {
    text: {
        '品牌升级': '{name} 브랜드 업그레이드',
        '了解详情': '자세히 알아보기',
        '登录': '로그인',
        '注册': '회원 가입',
        '让您的交易方式': '귀하의 거래 방식을',
        '升级换代': '업그레이드',
        '选择全球最大的零售经纪商进行交易，畅享领先市场的条件。': '글로벌 최대 소매 중개업체를 선택하여 선도 시장 조건을 즐기세요.',
        '尝试免费模拟账户': '무료 모의 계정 시도',
        '70万+名活跃客户': '70만 명 이상의 활성 고객',
        '持有多个监管牌照': '다수의 규제 허가 보유',
        '24/7 中文客服': '24/7 중국어 고객 서비스',
        'PCI DSS认证': 'PCI DSS 인증',
        '交易无需妥协': '거래를 위한 타협 없음',
        '出金': '출금',
        '即时出金': '즉시 출금',
        '一键即刻批准入金和出金': '원 클릭으로 즉시 입출금 승인',
        '爆仓': '청산',
        '减少30%爆仓': '청산률 30% 감소',
        '借力我们的专有爆仓保护功能': '독점 청산 보호 기능 활용',
        '您将势不可挡': '귀하는 멈출 수 없습니다',
        '执行速度': '실행 속도',
        '极速执行': '초고속 실행',
        '无惧大订单，所有订单均': '대규모 주문에 두려움 없음, 모든 주문은',
        '将以毫秒级别速度执行': '밀리초 단위로 실행됩니다',
        '隔夜利息': '오버나잇 이자',
        '0隔夜利息': '0 오버나잇 이자',
        '无惧隔夜持有杠杆头寸，任意调整持仓': '오버나잇 레버리지 포지션을 두려워하지 마세요. 포지션을 자유롭게 조정하세요.',
        '时长。条款和条件适用': '기간. 조건이 적용됩니다.',
        '进入全球市场，交易资产': '글로벌 시장에 진입하여 자산 거래',
        '把握全球最热门资产带来的每个机会': '글로벌 인기 자산의 모든 기회를 잡으세요',
        '品种': '다양성',
        '杠杆': '레버리지',
        '平均点差, 点': '평균 스프레드, 포인트',
        '免隔夜利息': '오버나잇 이자 면제',
        '黄金': '금',
        '美元': '미국 달러',
        '原油': '원유',
        '欧元': '유로',
        '美国华尔街30指数': '미국 월스트리트 30 지수',
        '定制化': '맞춤화',
        '支持': '지원',
        '金属': '금속',
        '能源': '에너지',
        '货币': '통화',
        '指数': '지수',
        '股票': '주식',
        '适用隔夜利息': '오버나잇 이자 적용',
        '把握每个机会': '모든 기회를 잡으세요',
        '随时、随地线上交易。网页端、手机端、电脑桌面端，供您任意选择': '언제 어디서나 온라인 거래. 웹, 모바일, 데스크톱 버전 모두 선택 가능',
        'MetaTrader 5': 'MetaTrader 5',
        'FTOOO 网页版': '{name} 웹 버전',
        'FTOOO交易应用': '{name} 거래 앱',
        '保护您的安全是我们的首要任务': '귀하의 안전을 보호하는 것이 우리의 주요 임무입니다',
        '从安全支付到负余额保护，我们全方位为您保驾护航': '안전한 지불에서 음수 잔액 보호까지, 우리는 귀하를 전폭적으로 지원합니다',
        '客户保护': '고객 보호',
        '为什么选择FTOOO': '{name} 선택 이유',
        '自信交易': '자신감 있는 거래',
        '让数字说话。获取全球最大零售经纪商的支持，自信交易': '세계 최대 소매 중개업체 지원을 받아 자신감 있게 거래하세요',
        '万亿': '조',
        '19亿': '19억',
        '走在市场前沿': '시장 선도',
        '获取最新市场资讯、交易分析和功能更新': '최신 시장 정보, 거래 분석 및 기능 업데이트를 얻으세요',
        '查看所有市场新闻': '모든 시장 뉴스 보기',
        '洞见': '통찰',
        '教育': '교육',
        '哪些公司将在2020年代生存下来': '어떤 기업들이 2020년대에 살아남을 것인가',
        '图表形态：真的能反映未来的价格走向吗': '차트 패턴: 실제로 미래 가격 방향을 반영할 수 있나요',
        '是什么在牵制比特币': '비트코인을 억제하는 것은 무엇인가',
        '即日起选择值得信赖的经纪商交易': '즉시 신뢰할 수 있는 중개업체 선택',
        '您亲自了解为什么FTOOO成为了70多万名交易者': '귀하 직접 알아보세요. 왜 {name}이(가) 70만 명이 넘는 트레이더와 6.4만 명이 넘는 파트너들의 선택이 되었는지',
        '账户': '계정',
        '标准型账户': '표준 계정',
        '专业型账户': '전문가 계정',
        '模拟账户': '모의 계정',
        '社交交易账户': '소셜 트레이딩 계정',
        '条件': '조건',
        '入金和出金': '입금 및 출금',
        '手续费': '수수료',
        '市场': '시장',
        '外汇差价合约(CFD)': '외환 차액 계약(CFD)',
        '大宗商品差价合约(CFD)': '대형 상품 차액 계약(CFD)',
        '股票差价合约(CFD)': '주식 차액 계약(CFD)',
        '指数差价合约(CFD)': '지수 차액 계약(CFD)',
        '加密货币差价合约(CFD)': ' 암호화폐 차액 계약(CFD)',
        '平台': '플랫폼',
        'MetaTrader 4': 'MetaTrader 4',
        'MetaTrader手机应用': 'MetaTrader 모바일 앱',
        'FTOOO交易终端': '{name} 거래 터미널',
        'MetaTrader网页终端': 'MetaTrader 웹 터미널',
        '工具': '도구',
        '分析工具': '분석 도구',
        '财经日历': '경제 캘린더',
        '投资计算器': '투자 계산기',
        '货币转换器': '통화 변환기',
        '跳动点历史记录': '스프레드 히스토리 기록',
        'VPS托管服务': 'VPS 호스팅 서비스',
        'Trading Central 网络电视': 'Trading Central 네트워크 TV',
        '关于 FTOOO': '{name} 소개',
        '联系我们': '문의하기',
        '帮助中心': '도움말 센터',
        '底部1': '회사 배경',
        '底部2': 'MOG 거래소는 미국 뉴욕 월스트리트에 본사를 두고 있으며, 정식 명칭은 모건 거래소이며, 모건 스탠리(MS) 투자 관리 기관에 속하는 주요 금융 서비스 자회사 중 하나입니다. 주요 사업 범위는 개인 증권 관리, 개인 자산 관리 및 VIP 투자 관리입니다.',
        '底部3': '회사의 강점',
        '底部4': '모건 스탠리는 뉴욕 시 맨해튼 미드타운 브로드웨이 1585번지에 본사를 두고 있는 미국의 다국적 투자 은행 및 금융 서비스 회사입니다. 이 회사는 41개국에 사무소를 두고 있으며, 75,000명 이상의 직원을 고용하고 있습니다. 고객은 기업, 정부, 기관, 개인을 포함합니다. 모건 스탠리는 2023년 포춘지 미국 기업 500대에서 총 수입으로 61위를 차지했으며, 포브스 글로벌 100대에서는 30위에 올랐습니다.',
        '底部5': '사용자 경험',
        '底部6': 'Mog Exchange는 모건 스탠리(MS)의 투자 관리 부문 자회사로, 국가 SEC의 규제를 받습니다. 또한, MOG는 2019년 9월에 설립된 종합적인 VIP 자산 관리 기관으로서 전체 금융 서비스를 제공합니다. MOG는 증권 거래, 선물 계약 거래, 주식 및 외환 거래, 채굴 풀 스테이킹 거래 등 4가지 주요 금융 거래 서비스를 제공합니다.',
        '底部7': 'MOG 거래소 웹사이트: https://mogexchange.net',
        '底部8': '회사 주소: 뉴욕시 맨해튼 미드타운 브로드웨이 1585번지',
        '底部9': '기업 이메일: customer.morgan@gmail.com',
        '底部10': '연락처 전화/WhatsApp: +1(646)3027648',
        '风险披露': '위험 공개',
        '防止洗钱': '돈 세탁 방지',
        '隐私政策': '개인 정보 보호 정책',
        '月交易量': '월 거래량',
        '2023年执行交易单数': '2023년 거래 건수',
        '注册合作伙伴': '등록된 파트너',
        'Trustpilot评分': 'Trustpilot 평점',
        'APP下载': '앱 다운로드',
        '比特币': '비트코인',
        '澳大利亚元': '오스트레일리아 달러',
        '亚马逊指数': '아마존지수',
    },
    ...krLocale
}

export default kr;